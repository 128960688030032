.live-winners {
  position: fixed;
  right: -5rem;
  top: 6rem;

  &__road {
    width: 42rem;
    height: 68.75rem;;
  }

  &__strapline {
    transform: rotate(-90deg);

    position: absolute;
    left: 21rem;

    top: 35%;

    .h3 {
      user-select: none;
    }
  }

  &__team {
    position: absolute;

    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;

    transition: 1s ease-in-out;

    &.third {
      left: 14rem;
      top: 11rem;
    }

    &.second {
      left: 0rem;
      top: 20rem;
    }

    &.first {
      left: 11rem;
      top: 32rem;
    }
  }

  &__img-container {
    transform: rotate(45deg);
    overflow: hidden;
    border-radius: 45px;
  }

  &__img {
    width: 190px;
    height: 190px;
    transform: rotate(-45deg) scale(1.42);

    &.third {
      width: 160px;
      height: 160px;
    }

    &.second {
      width: 175px;
      height: 175px;
    }

  }

  &__podium {
    width: 200px;
    height: 60px;

    &.second {
      width: 220px;
      height: 70px;
    }


    &.first {
      width: 300px;
      height: 86px;
    }
  }
}


.page-winners {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-around;

  padding-left: 14rem;
  padding-top: 8rem;



  &__team {

    max-width: 489px;

    overflow: visible;

    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;

    transition: 1s ease-in-out;


    .h3, .h2__winner {
      max-width: 450px;
      text-align: center;

      word-break: break-all;
    }

  }

  &__img-container {
    transform: rotate(45deg);
    overflow: hidden;
    border-radius: 45px;
  }


  &__img {
    width: 305px;
    height: 325px;
    transform: rotate(-45deg) scale(1.42);

    &.first {
      width: 350px;
      height: 370px;
    }
  }

  &__podium {
    width: 300px;
    height: 112px;

    &.first {
      width: 489px;
      height: 138px;
    }
  }
}