@font-face {
  font-family: 'OriginTech';
  src: local('OriginTech'), url(../fonts/origin-tech-demo.ttf) format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'), url(../fonts/proxima-nova-reg.otf) format('opentype');
}

$origintech: 'OriginTech';
$proximanova: 'ProximaNova';