.cover {
  &__logo {
    position: absolute;
    right: 3.5%;
    bottom: 5%;
    height: 343px;
    width: 56px;

    &-img {
      width: 100%;
      height: 100%;
    }
  }
}
