.timer {
  background: rgba(89, 89, 89, 0.14);
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 16px;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  width: 24rem;
  height: 7.5rem;

  position: absolute;
  top: 3.5rem;
  right: 5rem;

  &__boundary {
    background: rgba(255, 255, 255, 0.4);
    border: 2px solid #ffffff;
    border-radius: 20px;

    width: 55px;
    height: 97px;

    display: flex;
    justify-content: center;
    align-items: center;

    .h5 {
      position: absolute;
      bottom: 15px;
      font-size: 1rem;
    }
  }

  &__svg {
    width: 55px;

    height: 61px;

    transition: 3s ease-in-out;
  }
}
