@import "../../assets/styles/colours";
@import "../../assets/styles/fonts";

.sidebar {
  width: 13.75rem;

  background-color: $white;

  box-shadow: 9px 12px 4px rgba(0, 0, 0, 0.08);
  border-radius: 0px 70px 70px 0px;

  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-flow: column;
  justify-content: center;

  &__cta-container {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  &__strapline {
    transform: rotate(-90deg);
    margin-top: 18rem;

    .h2, .h4 {
      user-select: none;
    }
  }

  &__subheading {
    font-family: $origintech;
    color: $red;
  }
}