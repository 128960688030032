.cta {
  width: 10rem;
  height: 10rem;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.5;

  transition: 0.3s ease-in-out;

  &.active {
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }

  &__svg {
    width: 10rem;
    height: 10rem;
  }
}