.live {
  position: relative;

  &__progress {
    max-width: 60rem;
    padding-left: 18rem;

    padding-top: 3.5rem;
  }
}
