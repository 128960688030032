.completion {
  height: 2.5rem;
  width: 37.5rem;

  border: 4px solid #fff;
  border-radius: 120px;

  &__filled {
    height: 100%;
    background-color: #c4c4c4;

    border-radius: inherit;

    position: relative;

    transition: 3s ease-in-out;
  }

  &__svg {
    width: 55px;

    height: 61px;
    position: absolute;
    right: -25px;

    top: -15px;

    transition: 3s ease-in-out;
  }

  &__label {
    padding-top: 3px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .h5 {
      text-align: center;
    }
  }
}
