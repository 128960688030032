.background {
  width: 100%;
  height: 100%;

  opacity: 0.4;
  mix-blend-mode: screen;

  user-select: none;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: -1;
}