.h1 {
  font-family: $origintech;
  font-style: normal;
  font-weight: normal;

  font-size: 7.5rem; //120px;
  line-height: 7rem; //112px;

  color: $red;

  &__winner {
    font-family: $proximanova;
    font-weight: bold;
    font-size: 4.375rem; //70px;
    line-height: 5.313rem; //85px;

    text-transform: uppercase;

    color: $white;
  }
}

.h2 {
  font-family: $origintech;
  font-style: normal;
  font-weight: normal;

  font-size: 3.75rem; //60px;
  line-height: 3.5rem; //56px;
  color: $red;

  &__winner {
    font-family: $proximanova;
    font-weight: bold;
    font-size: 3.75rem; //60px;
    line-height: 4.563rem; //73px;

    text-transform: uppercase;

    color: $white;
  }
}

.h3 {
  font-family: $proximanova;
  font-style: normal;
  font-weight: bold;

  font-size: 2.5rem; //40px;
  line-height: 3.688rem; //59px;

  text-transform: uppercase;

  color: $white;
}

.h4 {
  font-family: $proximanova;
  font-style: normal;
  font-weight: bold;

  font-size: 2.438rem; //30px;
  line-height: 2.313rem; //37px;

  text-transform: uppercase;
  color: $white;
}

.h5 {
  font-family: $proximanova;
  font-style: normal;
  font-weight: normal;

  font-size: 1.375rem; //22px;
  line-height: 1.688rem; //27px;

  text-transform: uppercase;
  color: $white;
}
