.teams{
  position: relative;

  &__members {
    height: 100%;

    width: 100%;

    display: flex;
    flex-flow: row wrap;

    padding-left: 18rem;

    padding-top: 3.5rem;
  }

}