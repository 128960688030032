.member {
  width: 16rem;
  height: 20rem;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-right: 4rem;
  margin-bottom: 3rem;

  &__image {
    width: 100%;
    height: 15rem;

    border-radius: 45px;

    overflow: hidden;

    margin-bottom: 1.5rem;

    img {
      width: 100%;
      height: 100%;

      border-radius: 45px;

      overflow: hidden;
    }
  }

  .h4, .h5 {
    text-align: center;
    line-clamp: 1;

    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
