@import "../../assets/styles/colours";

.progress {
  width: 59rem;
  height: 11rem;

  border: 5px solid $white;
  border-radius: 45px;

  background: rgba(255, 255, 255, 0.5);

  display: flex;
  flex-flow: row nowrap;

  margin-bottom: 1.25rem;
  overflow: hidden;

  &__img {
    height: 100%;
    width: 16rem;

    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0 45px 45px 0;   
    overflow: hidden;

    margin-right: 2rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 0 45px 45px 0;   

      object-fit: cover;

      transition: 0.5s ease-in-out;
      animation: fadein 1.5s ease-in-out;
    }
  }

  &__content{
    display: flex;
    flex-flow: column nowrap;

    align-items: center;
    justify-content: space-around;

    padding: .7rem 0;

    .h4, .h5 {
      text-align: center;
    }

  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}