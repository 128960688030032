.hero { 
  height: 100vh;
  width: 90%;

  border-radius: 0px 70px 70px 0px;

  box-shadow: 9px 12px 4px rgba(0, 0, 0, 0.08);

  padding-left: 80px;

  position: relative;

  overflow: hidden;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: -1;

    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: left;

    user-select: none;
  }

  &__container {
    height: 100%;

    display: flex;
    justify-content: center;
    flex-flow: column;

    &-row {
      display: flex;
      flex-flow: row;

      margin-top: 4.375rem;
    }
  }

  &__cta-spacer {
    margin-right: 1.25rem;
  }
}